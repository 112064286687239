import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';

import { injectedComponent, Text, useText } from '@base/core';
import { EMAIL_REGEXP } from '../../configs/constants';

export default injectedComponent(
  {
    name: 'AuthDialogBase',
    services: ['api'],
    styles: ['dialog', 'targetDialog', 'button', 'self'],
  },
  function AuthDialogBase({
    onSNLogin,
    login,
    onLoginChange,
    password,
    onPasswordChange,
    updating,
    authError,
    validationId,
    validity,
    validate,
    title,
    beforeActionButton,
    actionButtonText,
    onAction,
    afterActionButton,
    services: { api },
    styles: {
      dialog: dialogStyles,
      targetDialog: targetDialogStyles,
      button: buttonStyles,
      self: selfStyles,
    },
  }) {
    const text = useText();

    const [isPasswordShown, setPasswordShown] = useState(false);

    const loginRef = useRef();
    const passwordRef = useRef();

    const validateOwn = () =>
      loginRef.current.checkValidity() && passwordRef.current.checkValidity();

    const handleLoginChange = (e) => {
      validate(validationId, validateOwn());
      onLoginChange(e.target.value);
    };

    const handlePasswordChange = (e) => {
      validate(validationId, validateOwn());
      onPasswordChange(e.target.value);
    };

    const handleEmailKeyPress = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        if (!password) {
          passwordRef.current.focus();
        } else if (login && password) {
          onAction();
        }
      }
    };

    const handlePasswordKeyPress = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        if (login && password) {
          onAction();
        }
      }
    };

    const handlePasswordShow = (e) => {
      e.stopPropagation();
      e.preventDefault();
      setPasswordShown(!isPasswordShown);
    };

    const handleOKLogin = () => {
      typeof onSNLogin === 'function'
        ? onSNLogin('ok', api.baseUrl)
        : document.location.assign(api.baseUrl + '/auth/ok');
    };

    const handleMailruLogin = () => {
      typeof onSNLogin === 'function'
        ? onSNLogin('mailru', api.baseUrl)
        : document.location.assign(api.baseUrl + '/auth/mailru');
    };

    const handleYandexLogin = () => {
      typeof onSNLogin === 'function'
        ? onSNLogin('yandex', api.baseUrl)
        : document.location.assign(api.baseUrl + '/auth/yandex');
    };

    const handleVKLogin = () => {
      typeof onSNLogin === 'function'
        ? onSNLogin('vk', api.baseUrl)
        : document.location.assign(api.baseUrl + '/auth/vk');
    };

    useEffect(() => {
      validate(validationId, validateOwn());
    }, []);

    return (
      <div className={clsx(targetDialogStyles.dialog, selfStyles.authDialog)}>
        <h3 className={dialogStyles.dialogTitle}>{title}</h3>
        <div className={selfStyles.authButtonGroup}>
          <button
            type="button"
            className={clsx(selfStyles.authButton, selfStyles.authButton__yandex)}
            onClick={handleYandexLogin}
            aria-label="Войти с Яндекс ID"
          ></button>
          <button
            type="button"
            className={clsx(selfStyles.authButton, selfStyles.authButton__vk)}
            onClick={handleVKLogin}
            aria-label="Войти с VK ID"
          ></button>
          <button
            type="button"
            className={clsx(selfStyles.authButton, selfStyles.authButton__ok)}
            onClick={handleOKLogin}
            aria-label="Войти через ОК"
          ></button>
          <button
            type="button"
            className={clsx(selfStyles.authButton, selfStyles.authButton__mailru)}
            onClick={handleMailruLogin}
            aria-label="Войти через Почта Mail"
          ></button>
        </div>
        <p className={selfStyles.dividerBox}>
          <span className={selfStyles.dividerText}></span>
          <span className={selfStyles.divider}></span>
        </p>
        <form className={clsx(dialogStyles.form, selfStyles.authForm)}>
          <input
            ref={loginRef}
            type="email"
            autoComplete="username"
            className={dialogStyles.formInput}
            placeholder={text('dialog.login.placeholder__email')}
            pattern={EMAIL_REGEXP}
            value={login}
            onChange={handleLoginChange}
            onKeyPress={handleEmailKeyPress}
          />
          <span className={selfStyles.passwordWrapper} data-validated="false">
            <input
              ref={passwordRef}
              type={isPasswordShown ? 'text' : 'password'}
              autoComplete="current-password"
              className={dialogStyles.formInput}
              placeholder={text('dialog.login.placeholder__password')}
              pattern=".{6,}"
              value={password}
              onChange={handlePasswordChange}
              onKeyPress={handlePasswordKeyPress}
            />
            <button
              className={clsx(selfStyles.passwordVisibilityBtn, {
                [selfStyles.showPassword]: !isPasswordShown,
              })}
              onClick={handlePasswordShow}
            ></button>
          </span>
          {authError && (
            <span className={clsx(dialogStyles.errorMessage, selfStyles.errorMessage)}>
              {authError}
            </span>
          )}
          {beforeActionButton}
          <button
            type="button"
            className={clsx(buttonStyles.primaryBtn, buttonStyles.primaryBtn__medium, {
              [buttonStyles.primaryBtn__disabled]: updating || !validity,
            })}
            onClick={onAction}
          >
            {actionButtonText}
          </button>
          {afterActionButton}
        </form>
      </div>
    );
  }
);
