import React, { useRef, useEffect } from 'react';

import { injectedComponent } from '@base/core';

export default injectedComponent(
  {
    name: 'ModalDialog',
    styles: ['self'],
  },
  function ModalDialog({ children, onClose, styles: { self: selfStyles } }) {
    const modalRef = useRef();
    const handleDialogClick = (e) => {
      e.stopPropagation();
      return false;
    };

    useEffect(() => {
      modalRef.current.scrollTop = modalRef.current.scrollHeight;
    });

    return (
      <div className={selfStyles.modalContainer}>
        <div id="modal" className={selfStyles.modal} ref={modalRef} onClick={onClose}>
          <div id="modal-window" className={selfStyles.modalWindow} onClick={handleDialogClick}>
            {children}
          </div>
          <div className={selfStyles.closeBtn}>
            <div className={selfStyles.closeBtnLine}></div>
          </div>
        </div>
      </div>
    );
  }
);
