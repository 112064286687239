import React, { useRef, useEffect, useState } from 'react';

import { injectedComponent } from '@base/core';

export default injectedComponent(
  {
    name: 'DefaultAvatar',
    styles: ['avatar'],
  },
  function DefaultAvatar({ initials, styles: { avatar: avatarStyles } }) {
    const ref = useRef();
    const [size, setSize] = useState(0);
    const style = size ? { fontSize: size / 2.3 } : {};

    useEffect(() => {
      const rect = ref.current.getBoundingClientRect();
      if (rect.height > 0) {
        setSize(rect.height);
      }
    });

    return (
      <div ref={ref} className={avatarStyles.defaultAvatar} style={style}>
        {initials}
      </div>
    );
  }
);
