// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2or36x___dialog {\n  width: 470px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  position: relative;\n  margin-top: 17px;\n}\n\n@media screen and (max-width: 500px) {\n  .b7eior___dialog {\n    width: 100%;\n  }\n}\n\n.qa4gr8___registeredEmail {\n  margin-top: 10px;\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://./packages/moyopros/public/src/styles/common/dialog.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".dialog {\n  width: 470px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  position: relative;\n  margin-top: 17px;\n}\n\n@media screen and (max-width: 500px) {\n  .dialog {\n    width: 100%;\n  }\n}\n\n.registeredEmail {\n  margin-top: 10px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "_2or36x___dialog b7eior___dialog",
	"registeredEmail": "qa4gr8___registeredEmail"
};
export default ___CSS_LOADER_EXPORT___;
